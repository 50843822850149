<template>
  <v-layout
    class="two-truth"
    :class="{ 'two-truth--mobile': mobile }"
    column
    justify-center
    fill-height
    ref="layout"
  >
    <!-- Options -->
    <template v-if="showOptions">
      <div
        v-if="isScribe"
        class="two-truth__options"
        :class="missionOptionsClass"
      >
        <TextField
          size="sm"
          v-model="answer1"
          class="mb-3"
          label="Enter a TRUTH about yourself (make it sound like it could be a lie)"
          placeholder="Truth #1"
          allowFreeSubmit
          status="active"
          :disabled="missionSuccess"
          :maxlength="maxTextLength"
          @onSubmit="submit()"
        />
        <TextField
          size="sm"
          v-model="answer2"
          class="mb-3"
          label="Enter another TRUTH about yourself (make it sound like it could be a
            lie)"
          placeholder="Truth #2"
          allowFreeSubmit
          status="active"
          :autofocus="false"
          :disabled="missionSuccess"
          :maxlength="maxTextLength"
          @onSubmit="submit()"
        />
        <TextField
          size="sm"
          v-model="answer3"
          class="mb-3"
          label="Enter a LIE about yourself (make it sound like it could be true)"
          placeholder="Lie"
          allowFreeSubmit
          status="active"
          :autofocus="false"
          :disabled="missionSuccess"
          :maxlength="maxTextLength"
          @onSubmit="submit()"
        />

        <RtbButton @click="submit()" :disabled="btnDisabled">
          {{ missionSuccess ? "SUBMITTED" : "SUBMIT" }}
        </RtbButton>
      </div>

      <template v-else>
        <h3>Please Stand By.</h3>
        <p>The Scribes are entering the Two Truths and a Lie.</p>
      </template>
    </template>
    <template v-else-if="mode !== 'social'">
      <v-flex d-flex>
        <ResizableText :message="instructions" />
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import { RtbButton } from "@/components/ui"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
export default {
  name: "TwoTruths",
  components: {
    RtbButton,
    TextField,
    ResizableText
  },
  mixins: [GameMixin],
  props: {
    mobile: {
      type: Boolean,
      default: undefined
    },
    mode: String,
    mission: Object
  },
  data() {
    return {
      // submited: false,
      status: "active",
      maxTextLength: 45,
      answer1: "",
      answer2: "",
      answer3: "",
      btnDisabled: false
    }
  },
  watch: {
    missionCompleted(newValue) {
      if (newValue) {
        this.btnDisabled = true
      } else {
        this.btnDisabled = false
      }
    }
  },
  computed: {
    ...mapGetters(["missionSuccess", "missionCompleted", "getCurrentMission"]),
    missionOptionsClass() {
      return {
        "two-truth__options--show": this.showOptions,
        "two-truth__options--hide": !this.showOptions
      }
    },
    showOptions() {
      return this.mode === "play" || this.mode === "huddle"
    },
    instructions() {
      return this.getCurrentMission?.instructions
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    }
  },
  methods: {
    async submit() {
      if (!this.isScribe) return
      if (this.answer1 && this.answer2 && this.answer3) {
        this.btnDisabled = true
        const array = [this.answer1, this.answer2, this.answer3]
        this.answer = array
        await this.checkAnswer()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.two-truth {
  $block: &;

  &__options {
    position: relative;
    max-height: 0;
    padding-right: 24px;
    padding-left: 24px;

    #{$block}--mobile & {
      padding-right: 0;
      padding-left: 0;
    }

    &--show {
      max-height: 100%;
      transition: max-height 1s ease-out;
    }

    &--hide {
      transition: max-height 0.5s ease-out 0.25s;
    }
  }
}

.mission-instructions-row {
  user-select: none;
  max-height: 40%;
  padding-left: 5px;
  padding-right: 5px;
}

.mission-instructions-row.full-size {
  max-height: 100%;
  margin-top: 21px;
  transition: all 0s ease-out 0.25s;
}

.mission-instructions-row.invisible {
  visibility: hidden;
}

.mission-instructions {
  color: $color-black;
  padding: 10px;
  line-height: 1.2em;
}

.appear-enter {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.appear-enter-active {
  opacity: 1;
}
</style>
