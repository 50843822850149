var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      ref: "layout",
      staticClass: "two-truth",
      class: { "two-truth--mobile": _vm.mobile },
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.showOptions
        ? [
            _vm.isScribe
              ? _c(
                  "div",
                  {
                    staticClass: "two-truth__options",
                    class: _vm.missionOptionsClass,
                  },
                  [
                    _c("TextField", {
                      staticClass: "mb-3",
                      attrs: {
                        size: "sm",
                        label:
                          "Enter a TRUTH about yourself (make it sound like it could be a lie)",
                        placeholder: "Truth #1",
                        allowFreeSubmit: "",
                        status: "active",
                        disabled: _vm.missionSuccess,
                        maxlength: _vm.maxTextLength,
                      },
                      on: {
                        onSubmit: function ($event) {
                          return _vm.submit()
                        },
                      },
                      model: {
                        value: _vm.answer1,
                        callback: function ($$v) {
                          _vm.answer1 = $$v
                        },
                        expression: "answer1",
                      },
                    }),
                    _c("TextField", {
                      staticClass: "mb-3",
                      attrs: {
                        size: "sm",
                        label:
                          "Enter another TRUTH about yourself (make it sound like it could be a\n          lie)",
                        placeholder: "Truth #2",
                        allowFreeSubmit: "",
                        status: "active",
                        autofocus: false,
                        disabled: _vm.missionSuccess,
                        maxlength: _vm.maxTextLength,
                      },
                      on: {
                        onSubmit: function ($event) {
                          return _vm.submit()
                        },
                      },
                      model: {
                        value: _vm.answer2,
                        callback: function ($$v) {
                          _vm.answer2 = $$v
                        },
                        expression: "answer2",
                      },
                    }),
                    _c("TextField", {
                      staticClass: "mb-3",
                      attrs: {
                        size: "sm",
                        label:
                          "Enter a LIE about yourself (make it sound like it could be true)",
                        placeholder: "Lie",
                        allowFreeSubmit: "",
                        status: "active",
                        autofocus: false,
                        disabled: _vm.missionSuccess,
                        maxlength: _vm.maxTextLength,
                      },
                      on: {
                        onSubmit: function ($event) {
                          return _vm.submit()
                        },
                      },
                      model: {
                        value: _vm.answer3,
                        callback: function ($$v) {
                          _vm.answer3 = $$v
                        },
                        expression: "answer3",
                      },
                    }),
                    _c(
                      "RtbButton",
                      {
                        attrs: { disabled: _vm.btnDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.submit()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.missionSuccess ? "SUBMITTED" : "SUBMIT"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : [
                  _c("h3", [_vm._v("Please Stand By.")]),
                  _c("p", [
                    _vm._v(
                      "The Scribes are entering the Two Truths and a Lie."
                    ),
                  ]),
                ],
          ]
        : _vm.mode !== "social"
        ? [
            _c(
              "v-flex",
              { attrs: { "d-flex": "" } },
              [_c("ResizableText", { attrs: { message: _vm.instructions } })],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }